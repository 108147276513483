/* eslint-disable no-undef */
/* eslint-disable react/jsx-key */
//react
import './ConfirmOrder.css'

//material
import {Grid} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {groupBy, mapValues} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'

import LoginModal from '../../../components/loginModal/Modal'
import {getPrice, getProductDiscount} from '../../../components/Product/Price'
//librerias
import {numberWithCommas} from '../../../components/Utils/NumberWithCommas'
import useIntersection from '../../../components/Utils/useIntersection'
import config from '../../../config'
import {useExtraDiscountContext} from '../../../context/CartContext'
//hooks y context
import {HideFooter, HideSearchBar} from '../../../context/LayoutContext'
import {useMarketContext} from '../../../context/MarketContext'
import {usePriceListIDContext, usePriceListsDataContext} from '../../../context/PriceListContext'
import {useCreateComment, useProcessDispatch, useProcessState} from '../../../context/ProcessContext'
import {useSelectedStore, useUserData} from '../../../context/UserContext'
import {db, FieldValue, useDocumentDataOnce} from '../../../firebase'
import ticketLogo from '../../../images/carritoResumen.png'
import mercadoPagoLogo from '../../../images/mercadopago-logo.png'
import {checkContainsLocation} from '../address'
import CartList from '../cart/CartList'
import {MessageFeedbackCart} from '../cart/messageFeedbackCart'
//components
import {purchase_min4free, shippingPrice, usePriceList} from '../shipoptionselect'
import {OrderDetails} from './OrderDetails'
//styles
import useStyles from './styles'

export default function ConfirmOrder() {

  const classes = useStyles()
  const history = useHistory()
  const [newOrderData, setNewOrderData] = useState({})
  const {comment: customerComments} = useCreateComment()
  const user = useUserData()
  const ProcessState = useProcessState()
  const ProcessDispatch = useProcessDispatch()
  const marketContext = useMarketContext()
  const priceList = usePriceList()
  const priceListID = usePriceListIDContext()
  const priceListsData = usePriceListsDataContext()
  const {store} = useSelectedStore()
  const isStore = config.stores && (!priceList?.addresses || !user.selectedAddress)
  const [selectedAddress] = useDocumentDataOnce('addresses', !isStore && user.selectedAddress)
  const address = !isStore ? selectedAddress : store?.address
  const {invoiceData} = useUserData()


  // const [invoice, setInvoice] = useState()
  const invoice = Object.entries(invoiceData || {}).sort(([, a], [, b]) => b.lastUsed - a.lastUsed)?.[0]?.[1]

  const userAddressee = user.addressee || user.name
  const [addressee, setAddressee] = useState(userAddressee || undefined)
  useEffect(() => {
    if (addressee === undefined && userAddressee) setAddressee(userAddressee)
  }, [userAddressee, addressee])


  // const carriersFromOrder = store?.carriers;
  const [shippingsMatch, setShippingsMatch] = useState()
  const [shippingId, setShippingId] = useState(undefined)
  const brands = marketContext?.brands
  const specs = marketContext?.specs
  const gt750px = useMediaQuery('(min-width:750px)')


  const customDiscounts = {}
  //{specs:{'KImzcGzUD57afNA0SMDE':{discount:0.15,plus:true}}, brands:{'VQTAn9M50USdKZB5rhJb':{discount:0.12,plusOthers:true}}};
  const customDiscountsString = JSON.stringify(customDiscounts)

  const [CartListEl, cartValues, , hits, cart] = CartList({customDiscounts})

  const subtotal = newOrderData.subtotal || cartValues.subtotal,
    iva = newOrderData?.iva ?? cartValues?.iva,
    // subtotal_plus_iva = subtotal + iva,//cartValues.subtotal_plus_iva,
    subtotal_plus_iva = (newOrderData.items &&
      Object.entries(newOrderData.items).reduce((p, [, value]) => p + (value.q * value.price * (1 + value.iva)), 0)) || 0,
    discounts_plus_iva = (newOrderData.items &&
      Object.entries(newOrderData.items).reduce((p, [, value]) => p +
        (value.q * value.price * value.discount * (1 + value.iva)), 0)) || cartValues.discounts_plus_iva

  const discounts = newOrderData.discount || 0
  const shipping = shippingId && marketContext.shippings?.[shippingId]
  //coordenadas
  const shipment = shipping?.next_shipments?.reduce((a, c) =>
    a && a.deadline > new Date().getTime() && a.deadline < c.deadline ? a : c)
  //fecha de entrega
  HideSearchBar()

  const shipping_price = priceList && subtotal && shippingPrice(shipping, subtotal - discounts + (priceList.iva_included ? iva : 0), priceList)
  const purchase_min4free_value = priceList && purchase_min4free(shipping, priceList)
  const RowItem = ({row}) => <>
    {row[2] && <Grid item
                     xs={12}>
      <Divider className={classes.dividerCart}/>
    </Grid>}
    {gt750px ?
      <div style={{display: 'flex'}}>
        <Grid item
              xs={7}>
          <div className={classes.leftGridItem}>{row[0]}</div>
        </Grid>
        <Grid item
              xs={5}>
          <div className={classes.rightGridItem}>{row[1]}</div>
        </Grid>
      </div>
      :
      <>
        <Grid item
              xs={7}>
          <div className={classes.leftGridItemMobile}>{row[0]}</div>
        </Grid>
        <Grid item
              xs={5}>
          <div className={classes.rightGridItemMobile}>{row[1]}</div>
        </Grid></>}
  </>

  const paymentOptions = store?.agreedPaymentMethod?.length ? store.agreedPaymentMethod : priceList.paymentOptions
  const paymentMethodId = paymentOptions?.includes(user.lastPaymentMethod) ? user.lastPaymentMethod : paymentOptions?.[0]
  const paymentMethod = marketContext.paymentOptions[paymentMethodId]
  const extraDiscount = useExtraDiscountContext()

  useEffect(() => {
    Object.keys(hits || {}).length && setNewOrderData(newOrder({
      // carriers: carriersFromOrder,
      cart_items: cart,
      priceLists: priceListsData,
      priceListID,
      products: hits,
      brands,
      specs,
      address,
      subtotal,
      shipping_price,
      user,
      shipping,
      shipment,
      ProcessState,
      paymentMethod,
      paymentMethodId,
      extraDiscount,
      customerComments,
      addressee,
      invoice,
      store,
      customDiscounts: JSON.parse(customDiscountsString),
    }))
  }, [cart,
    // carriersFromOrder,
    store,
    priceListsData,
    priceListID,
    hits,
    brands,
    address,
    subtotal,
    shipping_price,
    user,
    shipping,
    shipment,
    ProcessState,
    paymentMethod,
    paymentMethodId,
    extraDiscount,
    customerComments,
    addressee,
    invoice,
    customDiscountsString
  ])
  const mp_comission = !paymentMethod?.commission ? 0 : (subtotal -
    discounts + iva + (shipping && shipping_price ? shipping_price : 0)) * (paymentMethod?.commission || 0)
  const totalToPay = subtotal + (shipping_price || 0) - discounts + iva + mp_comission
  const descuentosCarrito = discounts && priceList.iva_included ? discounts_plus_iva : discounts
  const total = priceList.iva_included ? subtotal_plus_iva : subtotal

  //es el descuento en porcentaje
  const totalPerDiscount = ((descuentosCarrito / total * 100).toFixed())
  useEffect(() => {
    if (address) {
      let google = window.google
      if (google) {
        let point = new google.maps.LatLng(address?.geometry?.location.lat, address?.geometry?.location.lng)
        let shippings = checkContainsLocation(point, marketContext.shippings)
        setShippingsMatch(shippings)
        setShippingId(shippings?.[0]?.objectID || config.defaultShipping || '')
      }
    }
  }, [address, marketContext.shippings])

  const refTotal = useRef()
  const inViewportTotal = useIntersection(refTotal, '0px')

  const [inViewport, setInViewPort] = useState(false)

  useEffect(() => {
    inViewportTotal && !inViewport && Object.keys(cart).length && setInViewPort(true)
  }, [inViewportTotal, inViewport, cart])

  HideFooter(!gt750px)

  const summaryTemplate = (
    [
      ['Productos (' + Object.keys(cart).reduce((c, id) => c + cart[id].q, 0) + ')', '$ ' + numberWithCommas((priceList.iva_included ? subtotal_plus_iva : subtotal), 0)],
      [!totalPerDiscount < 3 && 'Descuento ', discounts && '-$ ' + numberWithCommas(priceList.iva_included ? discounts_plus_iva : discounts, 0)],
      ['Subtotal', (!priceList.iva_included || shipping_price) &&
      <b>{'$ ' + numberWithCommas(subtotal - discounts + (!priceList.iva_included ? 0 : iva))}</b>, true],
      ['IVA', !priceList.iva_included && '$ ' + numberWithCommas(iva, 0)],
      [<>Envío {!!shipping_price && <span
        style={{fontSize: 10}}><br/>(Comprando $ {numberWithCommas(purchase_min4free_value, 0)}{!priceList.iva_included && ' +IVA'} o más obtenes envío Gratis)</span>}</>, (shipping_price ? '$ ' + numberWithCommas(shipping_price, 0) : 'Gratis')],
      [<>Comisión <img className={classes.comisionPaymentMessageLogo}
                       src={paymentMethod?.imageWide || paymentMethod?.image}
                       alt={'Método de Pago'}/></>, mp_comission && '$ ' + numberWithCommas(mp_comission, 0)],
      ['Pagás', <b>{'$ ' + numberWithCommas(totalToPay, 0)}</b>, true],
    ].filter(r => r[1]).map((row, i) => <RowItem key={i}
                                                 row={row}/>))

  return <div>
    <div style={{display: 'flex'}}>
      <Button style={{margin: '-10px'}}
              onClick={() => history.push('/app/products')}><ArrowBackIcon/></Button>
      <h2 style={{fontWeight: '500', fontSize: '28px', color: 'black', lineHeight: '1.9'}}>Resumen del pedido</h2>
    </div>
    {/* SI NO HAY PRODUCTOS EN EL CARRITO SE MUESTRA UNA LEYENDA CON UN BOTON QUE TE ENVIA AL AREA DE PRODUCTOS.*/}
    {cart && Object.keys(cart).length ?
      // SI EL ANCHO DE LA PANTALLA ES MAYOR A 750px SE EJECUTA EL PRIMER CODIGO QUE TIENE UN ESTILO Y ORDEN DIFERENTE A LA VISTA EN CELULAR.
      gt750px ?
        // RESOLUCION > 750px
        <div className={classes.root}
             style={{display: 'flex', flexDirection: 'row-reverse'}}>
          <Grid item
                xs={5}
                style={{position: 'sticky', top: '0', height: '10vh', minHeight: '40vh', marginLeft: '10px'}}>
            <Grid style={{display: 'flex', flexDirection: 'column'}}
                  className={classes.yellowBackground}>
              <Grid style={{display: 'flex', justifyContent: 'space-between', margin: '1vw'}}
                    item
                    xs={12}>
                <h3 className={classes.title}>Resumen</h3>
                <img alt={'ticketLogo'} style={{width: '80px', height: '80px'}}
                     src={ticketLogo}></img>
              </Grid>
              {summaryTemplate}
              <Grid item
                    xs={12}
                    className={classes.confirmButtonElement}>
                <ConfirmButton {...({
                  isStore,
                  inViewport,
                  newOrderData,
                  addressee,
                  invoice,
                  user,
                  classes,
                  cart,
                  priceListsData,
                  priceListID,
                  paymentMethod,
                  hits,
                  brands,
                  address,
                  subtotal,
                  shipping_price,
                  discounts: discounts,
                  shipping,
                  shipment,
                  ProcessState,
                  ProcessDispatch,
                  history,
                  shippingsMatch,
                  setAddressee
                })} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item
                xs={7}>
            <div
              style={{paddingBottom: '1.2vh'}}>{Object.keys(cart).reduce((c, id) => c + cart[id].q, 0) === 1 ? Object.keys(cart).reduce((c, id) => c + cart[id].q, 0) + ' ' + 'producto' : Object.keys(cart).reduce((c, id) => c + cart[id].q, 0) + ' ' + 'productos'} de <span
              style={{fontWeight: '700'}}> Plant</span></div>
            {CartListEl}
            {!user.isAnonymous && <OrderDetails {...{
              setInViewPort,
              shippingsMatch,
              addressee,
              subtotal_plus_iva,
              paymentMethod,
              shipping,
              discounts,
              priceList,
              iva,
              subtotal,
              shipment,
              address,
              store,
              isStore,
              classes,
              paymentOptions,
              setAddressee
            }} />}
          </Grid>
        </div>
        :
        // RESOLUCION CELULAR
        <div className={classes.root}>
          <div style={{
            paddingBottom: '1.2vh',
            fontSize: '20px',
            textAlign: 'center',
            paddingTop: '1.5vh'
          }}>{Object.keys(cart).reduce((c, id) => c + cart[id].q, 0) === 1 ? Object.keys(cart).reduce((c, id) => c + cart[id].q, 0) + ' ' + 'producto' : Object.keys(cart).reduce((c, id) => c + cart[id].q, 0) + ' ' + 'productos'} de <span
            style={{fontWeight: '700'}}> Plant</span></div>
          {CartListEl}
          <div className={classes.boxConfirmSeparator}>
            {!user.isAnonymous && <OrderDetails {...{
              setInViewPort,
              shippingsMatch,
              addressee,
              subtotal_plus_iva,
              paymentMethod,
              shipping,
              discounts,
              priceList,
              iva,
              subtotal,
              shipment,
              address,
              store,
              isStore,
              classes,
              paymentOptions,
              setAddressee
            }} />}
            <Grid
              container
              direction="row"
              alignContent="center"
              alignItems={'center'}
              justifyContent={'center'}
              className={classes.yellowBackground750px}
            >
              <Grid item
                    xs={12}>
                <h3 className={classes.titleMobile}>Resumen</h3>
              </Grid>
              {summaryTemplate}
              <div id={'hola'}
                   ref={refTotal}/>
              {paymentMethod?.mercadopago &&
                <Grid item
                      xs={12}
                      className={classes.PaymentMessage}>Medio de Pago: <img
                  className={classes.PaymentMessageLogo}
                  src={mercadoPagoLogo}
                  alt={'Mercado Pago'}/></Grid>}
              <Grid item
                    xs={12}
                    className={classes.confirmButtonElementCellphone}>
                <ConfirmButton {...({
                  isStore,
                  newOrderData,
                  addressee,
                  invoice,
                  inViewport,
                  user,
                  classes,
                  cart,
                  priceListsData,
                  priceListID,
                  paymentMethod,
                  hits,
                  brands,
                  address,
                  subtotal,
                  shipping_price,
                  discounts: discounts,
                  shipping,
                  shipment,
                  ProcessState,
                  ProcessDispatch,
                  history,
                  shippingsMatch
                })} />
              </Grid>
            </Grid>
          </div>
        </div>
      :
      // MENSAJE CARRITO VACIO CON EL CALL TO ACTION
      <div>
        <MessageFeedbackCart/>
      </div>
    }
  </div>

}

function ConfirmButton({
                         inViewport,
                         newOrderData,
                         user,
                         classes,
                         cart,
                         // paymentMethod,
                         address,
                         // shipping,
                         ProcessDispatch,
                         history,
                       }) {
  const [loading, setLoading] = useState(true)
  const [continueClicked, setContinueClicked] = useState(false)

  useEffect(() => {
    setLoading(!newOrderData?.total)
  }, [newOrderData])

  return (
    <>
      <div className="seguir-container">
        {/* BOTON CONFIRMAR COMPRA AL CUMPLIR CONDICIONES; BOTON CONTINUAR SI NO SE CUMPLEN. */}
        {user.isAnonymous ? <LoginModal text={'Continuar'}
                                        variant={'contained'}
                                        className={`${classes.confirmButton} btn-confirm`}/> :
          (inViewport || continueClicked) && address ?
            <Button
              disabled={loading}
              variant={'contained'}
              className={`${classes.confirmButton} btn-confirm`}
              onClick={async () => {
                setLoading(true)

                const orderID = await db.collection('orders').add({
                  ...newOrderData,
                  timestamp: FieldValue.serverTimestamp()
                }).then(r => r.id)
                db.collection('users').doc(user.uid).update(Object.keys(newOrderData.items).reduce((c, a) => ({
                  ...c,
                  ['cart.' + a]: FieldValue.delete()
                }), {}))
                ProcessDispatch({
                  newOrder: newOrderData,
                  orderID
                })

                //Google Analytics
                window?.dataLayer?.push({ecommerce: null})  // Clear the previous ecommerce object.
                window?.dataLayer?.push({
                  'event': 'checkout',
                  'ecommerce': {
                    'currencyCode': 'ARS',
                    'purchase': {
                      'actionField': {
                        'id': orderID,                         // Transaction ID. Required for purchases and refunds.
                        'affiliation': 'Online Store',
                        'revenue': newOrderData.total - (newOrderData.iva || 0),                     // Total transaction value (incl. tax and shipping)
                        'tax': (newOrderData.iva || 0),
                        'shipping': newOrderData.shipping_cost || 0,
                        'coupon': newOrderData.discountCode?.name || ''
                      },
                      'products': [Object.entries(cart).map(([id, value]) => ({
                        id,
                        'quantity': value.q
                      }))]
                    }
                  }
                })

                window?.gtag?.('event', 'conversion', {
                  'send_to': 'AW-660033280/1Aa-CNCF88oBEICe3boC',
                  'value': newOrderData.total - (newOrderData.iva || 0),
                  'currency': 'ARS',
                  'transaction_id': orderID
                })

                history.push(newOrderData.priceList === 'g' ? '/app/buy/success' : '/app/buy/success_business')

              }
              }>Confirmar</Button> :
            <Button variant={'contained'}
                    className={`${classes.confirmButton} btn-confirm`}
                    onClick={() => {
                      setContinueClicked(true)
                      !address ? history.push('/app/buy/addressselect') :
                        setTimeout(() => window.scrollTo({
                          top: document.body.scrollHeight,
                          left: 0,
                          behavior: 'smooth'
                        }), 200)
                    }}>
              Continuar
            </Button>
        }
      </div>
    </>
  )
}

//

function newOrder({
                    cart_items, priceLists, priceListID, products, brands, specs,
                    subtotal,
                    shipping_price,
                    user,
                    shipment,
                    shipping,
                    ProcessState,
                    address,
                    paymentMethod,
                    paymentMethodId,
                    extraDiscount,
                    customerComments,
                    addressee,
                    invoice,
                    customDiscounts,
                    store
                  }) {

  let newOrder = {
    items: {},
    customerComments,
    // carriers: carriers,
    addressee: addressee,
    invoice: invoice,
    subTotal: round2decimals(subtotal),
    'address': address?.objectID,
    // "carrier": ProcessState.carrierId,
    // "onlinePayment": !ProcessState.store || !!priceLists[priceListID].onlinePayment,
    'timestamp': new Date(),
    'priceList': priceListID,
    shipping: shipping?.objectID || config.defaultShipping,
    shipment: shipment?.id,
    'percentDiscount': Math.max(priceLists[priceListID].discount || 0, ProcessState.discountCode?.discount || 0),
    'seller': 'v',
    'user': user.uid,
    'shipping_cost': shipping_price || 0,
    paymentMethod: paymentMethodId,
    market_v: process.env.REACT_APP_MARKET_V,
    host: location?.host || '',
    iva: 0,
    discount: 0,
    algoliaUpdated: false,
    takeaway: !!ProcessState.takeaway,
    data: {
      address:
        {
          formatted_address: address?.formatted_address,
          details: address?.details,
          geometry: address?.geometry,
          name: address?.name,
          address_components: address?.address_components,
        },
    }
  }

  ProcessState.discountCode && (newOrder.discountCode = ProcessState.discountCode)
  store?.id && (newOrder.store = store?.id)
  store?.storeName && (newOrder.data.store = {storeName: store?.storeName});
  (user?.name || user?.storeName) && (newOrder.data.user = {name: (user?.name || user?.storeName)})

  document.cookie && (newOrder.cookie = mapValues(groupBy(document.cookie?.split('; ').map(d => ({
    id: d.split('=')[0],
    d: d.split('=')[1]
  })), 'id'), ([v]) => v.d))

  for (const itemID in cart_items) {
    const product = products[itemID]
    if (!product) continue
    if (!product?.nostock &&
      (product?.autoItem || !product?.showOnly?.length || product?.showOnly.includes(priceListID)
        || (priceLists[priceListID]?.mother && product?.showOnly.includes(priceLists[priceListID].mother)))) {

      const q = cart_items[itemID].q
      const price = round2decimals(getPrice({product, priceLists, priceListID, iva: false}))
      const discount = Math.max((ProcessState.discountCode?.type === 'percentage' && ProcessState.discountCode?.discount) || 0, getProductDiscount({
        product,
        priceLists,
        priceListID,
        brand: brands[product?.brand],
        specs: product.specs?.map?.(s => specs[s]),
        q,
        extraDiscount,
        customDiscounts
      }).discount)
      newOrder.items[itemID] = {
        q,
        price,
        discount,
        iva: store?.ivaExento ? 0 : product.iva ?? 0.21
      }

      !store?.ivaExento && (newOrder.iva += (product.iva ?? 0.21) * q * price * (1 - discount))
      newOrder.discount += q * price * discount
    }
  }

  newOrder.status = 'pending_payment'//newOrder.onlinePayment ? "pending_payment" : "in_process";

  newOrder.iva = round2decimals(newOrder.iva)

  if (ProcessState.discountCode?.type === 'fixed' && (ProcessState.discountCode?.discount > newOrder.discount || ProcessState.discountCode?.plus)) {
    const plusDiscount = (ProcessState.discountCode?.discount / (1 + newOrder.iva / (newOrder.subTotal - newOrder.discount)) -
      (ProcessState.discountCode?.plus ? 0 : newOrder.discount)) / subtotal
    newOrder.items = mapValues(newOrder.items, item => ({
      ...item,
      discount: Math.min(1, (item.discount || 0) + (plusDiscount || 0))
    }))
    newOrder.discount = (newOrder.items && Object.entries(newOrder.items)
      .reduce((p, [, value]) => p + (value.q * value.price * value.discount), 0))
    newOrder.iva = (newOrder.items &&
      Object.entries(newOrder.items).reduce((p, [, value]) => p + (value.q * value.price * (1 - value.discount) * (value.iva || 0.21)), 0))
  }

  paymentMethod?.commission && (newOrder.percent_mp_comission = paymentMethod?.commission)
  newOrder.percent_mp_comission && (newOrder.mp_comission = round2decimals((subtotal - newOrder.discount + newOrder.iva + newOrder.shipping_cost)
    * newOrder.percent_mp_comission || 0))

  newOrder.total = round2decimals(subtotal - newOrder.discount + newOrder.iva + newOrder.shipping_cost + (newOrder.mp_comission || 0))

  return removeEmpty(newOrder)
}

const removeEmpty = (obj) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key])
    else if (obj[key] === undefined) delete obj[key]
  })
  return obj
}

function round2decimals(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100
}
